import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Text from './text'

const FeatureCards = ({ cards, style }) => {
  return (
    <div css={[tw`flex flex-col space-y-8 lg:(flex-row space-y-0 space-x-4)`, style]}>
      {cards.map((card, i) => {
        return (
          <article key={i} css={tw`relative flex-equal`}>
            <div
              css={[
                tw`transition-opacity duration-200 ease-in-out
                h-full bg-smoke-grey py-12 px-6 lg:(py-20 px-10)`,
              ]}
            >
              <img loading="lazy" src={card.image.url} alt="" css={tw`mb-8 max-w-16 h-16`} />
              <Heading
                content={card.titleNode}
                headingType="h4"
                style={tw`text-primary-500 font-bold mb-8`}
              />
              <Text
                content={card.descriptionNode}
                style={css`
                  ul {
                    ${tw`divide-y divide-grey divide-opacity-20`}
                    li {
                      ${tw`py-4`}
                    }
                  }
                `}
              />
            </div>
          </article>
        )
      })}
    </div>
  )
}

FeatureCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default FeatureCards
