import React from 'react'
import Overlay from './overlay'
import Rectangle from './rectangle'

export const overlays = {
  hero: (
    <>
      <Overlay visibility="desktop" position="left" width={112} height={93}>
        <Rectangle x={0} y={56} width={112} height={28} fill="yellow" />
      </Overlay>
      <Overlay visibility="desktop" position="bottom-left" width={765} height={134}>
        <Rectangle x={681} y={56} width={28} height={28} fill="navy" />
        <Rectangle x={709} y={0} width={56} height={56} fill="white" />
      </Overlay>
      <Overlay visibility="desktop" position="right" width={336} height={322}>
        <Rectangle x={0} y={238} width={168} height={84} fill="navy" />
        <Rectangle x={168} y={182} width={56} height={56} fill="white" />
      </Overlay>
      <Overlay visibility="mobile" position="left" width={50.28} height={60.06}>
        <Rectangle x={0} y={40} width={50.28} height={20.06} fill="yellow" />
      </Overlay>
      <Overlay visibility="mobile" position="bottom-left" width={92.41} height={89.6}>
        <Rectangle x={59.8} y={0} width={32.41} height={39.6} fill="navy" />
        <Rectangle x={40} y={39.6} width={19.8} height={19.8} fill="white" />
      </Overlay>
      <Overlay visibility="mobile" position="right" width={80.75} height={337}>
        <Rectangle x={0} y={296.8} width={53.95} height={40.2} fill="navy" />
        <Rectangle x={53.95} y={270} width={26.8} height={26.8} fill="white" />
      </Overlay>
    </>
  ),
}
